import { isLocalStorageAvailable } from '~/utils/feature-check';

export const getObjectFromLocalStorage = <T>(key: string): T | undefined => {
  if (!isLocalStorageAvailable()) {
    return;
  }
  const json = window.localStorage.getItem(key);
  if (!json) {
    return;
  }
  return JSON.parse(json);
};

export const setObjectToLocalStorage = <T>(key: string, value: T): void => {
  if (!isLocalStorageAvailable()) {
    return;
  }
  window.localStorage.setItem(key, JSON.stringify(value));
};
