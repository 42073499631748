'use client';
import useSWR from 'swr';
import {
  clearReadingProgressItems,
  getReadingProgressItems,
} from '~/app/_hooks/use-local-reading-progress';
import { updateReadingProgress } from '~/app/_repositories/me-service';
import { sendToFirebaseAnalytics } from '~/common/hooks/use-firebase-analytics';

type Props = {
  children?: React.ReactNode;
};

const SEND_READING_PROGRESS_KEY = 'send-reading-progress';

const fetcher = async () => {
  const readingProgressItems = getReadingProgressItems();
  if (!readingProgressItems) return;
  const values = Object.values(readingProgressItems);

  // send event to firebase analytics (when isFinish is false)
  await Promise.all(
    values
      .filter((item) => !item.isFinish)
      .map((item) => {
        return sendToFirebaseAnalytics('story_read_leave', item);
      })
  );

  // send reading progress to server
  await Promise.all(
    values
      .filter((item) => item.progress > 0)
      .map((item) =>
        updateReadingProgress({
          story_id: item.storyId,
          index: item.progress,
        })
      )
  );

  clearReadingProgressItems();
};

export const SendReadingProgressProvider = ({ children }: Props) => {
  const readingProgressItems = getReadingProgressItems();

  useSWR(
    typeof readingProgressItems !== 'undefined'
      ? [SEND_READING_PROGRESS_KEY, ...Object.keys(readingProgressItems)]
      : undefined,
    fetcher
  );

  return <>{children}</>;
};
