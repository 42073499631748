import type { SnakeCasedPropertiesDeep } from 'type-fest';
import { fetchForClient } from '~/infra/api/fetch-api';
import type { me } from '~/infra/api/rpc';

type MeGetNotificationCountSinceRequestV2 =
  SnakeCasedPropertiesDeep<me.IMeGetNotificationCountSinceRequestV2>;
type MeGetNotificationCountSinceResponse =
  SnakeCasedPropertiesDeep<me.IMeGetNotificationCountSinceResponse>;
type MeUpdateReadingProgressRequest =
  SnakeCasedPropertiesDeep<me.IMeUpdateReadingProgressRequest>;
type MeUpdateReadingProgressResponse =
  SnakeCasedPropertiesDeep<me.IMeUpdateReadingProgressResponse>;

const SERVICE_PATH = '/me.MeService';

export const getNotificationSince = async (
  req: MeGetNotificationCountSinceRequestV2,
  option?: RequestInit
): Promise<number> => {
  const { count } = await fetchForClient<MeGetNotificationCountSinceResponse>({
    path: `${SERVICE_PATH}/GetNotificationCountSinceV2`,
    options: {
      ...option,
      method: 'POST',
      body: JSON.stringify(req),
    },
  });

  return count ?? 0;
};

export const updateReadingProgress = async (
  req: MeUpdateReadingProgressRequest,
  option?: RequestInit
) => {
  const { reading_progress } =
    await fetchForClient<MeUpdateReadingProgressResponse>({
      path: `${SERVICE_PATH}/UpdateReadingProgress`,
      options: {
        ...option,
        method: 'POST',
        body: JSON.stringify(req),
      },
    });

  return reading_progress;
};
