import { useCallback } from 'react';
import {
  getObjectFromLocalStorage,
  setObjectToLocalStorage,
} from '~/repositories/local-storage-repository';

const KEY = '_tn_reading_progress_items_';

export type ReadingProgressItem = {
  storyId: string;
  // チャット小説の場合は読み進めたタップ数、ノベルの場合は読んだ割合(1-100%)が設定される
  progress: number;
  // 読み終わったか
  isFinish: boolean;
};

export const getReadingProgressItems = () =>
  getObjectFromLocalStorage<Record<string, ReadingProgressItem>>(KEY);

export const setReadingProgressItems = (item: ReadingProgressItem) => {
  const items = getReadingProgressItems();
  const newItems = {
    ...items,
  };
  newItems[item.storyId] = item;
  setObjectToLocalStorage<Record<string, ReadingProgressItem>>(KEY, newItems);
};

export const clearReadingProgressItems = () => {
  setObjectToLocalStorage<Record<string, ReadingProgressItem>>(KEY, {});
};

export const removeReadingProgressItem = (storyId: string) => {
  const items = getReadingProgressItems();
  const newItems = {
    ...items,
  };
  delete newItems[storyId];
  setObjectToLocalStorage<Record<string, ReadingProgressItem>>(KEY, newItems);
};

export const useLocalReadingProgress = () => {
  const items = getReadingProgressItems();

  const update = useCallback((item: ReadingProgressItem) => {
    setReadingProgressItems(item);
  }, []);

  return {
    items,
    update,
  };
};
