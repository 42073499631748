'use client';

import { useCallback } from 'react';
import useSWR from 'swr';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { ShowSensitive, VipStatus } from '~/models/query-types';
import type { TellerUser } from '~/models/teller-user';
import { getQueriesForClient } from '@app/_repositories/query-service';

type Props = {
  children?: React.ReactNode;
};

type MinimalTellerUser = Pick<
  TellerUser,
  | 'name'
  | 'thumbnail'
  | 'followeesCount'
  | 'followersCount'
  | 'searchableStoryCount'
  | 'isVipUser'
  | 'showSensitive'
>;

const fetchTellerUser = (): Promise<MinimalTellerUser> => {
  return getQueriesForClient({
    request_list: [
      {
        request_id: 'fetch-teller-user',
        me_request: {
          require_user: {
            require_name: true,
            require_thumbnail: {
              require_serving_url: true,
            },
            require_followee: {
              cursor: {
                require_cursor_info: {
                  require_total_count: true,
                },
              },
            },
            require_follower: {
              cursor: {
                require_cursor_info: {
                  require_total_count: true,
                },
              },
            },
            require_searchable_story_count: true,
            require_vip_status: true,
          },
          require_show_sensitive: true,
        },
      },
    ],
  })
    .then((res) => {
      return res.response_list[0]?.me;
    })
    .then((me) => {
      const user = me?.user;
      return {
        name: user?.name ?? '',
        thumbnail: user?.thumbnail?.serving_url ?? null,
        followeesCount: user?.followee_page?.cursor?.total_count ?? 0,
        followersCount: user?.follower_page?.cursor?.total_count ?? 0,
        searchableStoryCount: user?.searchable_story_count ?? 0,
        isVipUser:
          user?.vip_status === VipStatus.VIP_STATUS_VIP ||
          user?.vip_status === VipStatus.VIP_STATUS_IN_TRIAL,
        showSensitive: (() => {
          if (!me?.show_sensitive) return null;
          return (
            me.show_sensitive ===
            ShowSensitive.SHOW_SENSITIVE_NOT_BLOCK_AND_LIST
          );
        })(),
      };
    });
};

export const UpdateTellerUserProvider = ({ children }: Props) => {
  const { session, update } = useTellerSession();

  const handleOnSuccessFetchIsVip = useCallback(
    (data: MinimalTellerUser) => {
      const tellerUser = session?.tellerUser;
      if (!tellerUser) return;

      if (
        data.name !== tellerUser.name ||
        data.thumbnail !== tellerUser.thumbnail ||
        data.followeesCount !== tellerUser.followeesCount ||
        data.followersCount !== tellerUser.followersCount ||
        data.searchableStoryCount !== tellerUser.searchableStoryCount ||
        data.isVipUser !== tellerUser.isVipUser ||
        data.showSensitive !== tellerUser.showSensitive
      ) {
        update({
          tellerUser: {
            ...tellerUser,
            ...data,
          },
        });
      }
    },
    [session?.tellerUser, update]
  );

  useSWR(
    session?.tellerUser && !session.isAnonymous ? 'get-teller-user' : null,
    fetchTellerUser,
    {
      onSuccess: handleOnSuccessFetchIsVip,
    }
  );

  return <>{children}</>;
};
