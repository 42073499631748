'use client';

import { SessionProvider } from 'next-auth/react';
import { AfterLoginNotificationProvider } from './after-login-notification-provider';
import { FirebaseAuthProvider } from './firebase-auth-provider';
import { UpdateTellerUserProvider } from './update-teller-user-provider';

type Props = {
  children?: React.ReactNode;
};

export const NextAuthProvider = ({ children }: Props) => {
  return (
    <SessionProvider>
      <FirebaseAuthProvider>
        <UpdateTellerUserProvider>
          <AfterLoginNotificationProvider>
            {children}
          </AfterLoginNotificationProvider>
        </UpdateTellerUserProvider>
      </FirebaseAuthProvider>
    </SessionProvider>
  );
};
