'use client';
import { ToastBar, Toaster } from 'react-hot-toast';

type Props = {
  children?: React.ReactNode;
};

export const SystemNotificationProvider = ({ children }: Props) => {
  return (
    <>
      {children}
      <Toaster>
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible
                ? 'enter 0.2s ease-out'
                : 'leave 0.2s forwards ease-out',
            }}
          />
        )}
      </Toaster>
    </>
  );
};
